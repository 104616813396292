import React, { useEffect, useRef } from 'react';
import './Item1.css';

const Item1 = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap&libraries=marker`;
      console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
      script.async = true;
      script.defer = true;
      window.initMap = initMap;
      document.head.appendChild(script);
    };

    const initMap = () => {
      if (mapRef.current) {
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 36.5, lng: 127.5 }, // 서울의 중심 좌표
          zoom: 7,
          mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID, // 지도 ID 추가
        });

        // 여러 개의 고급 마커를 추가합니다.
        const locations = [
          { lat: 37.5665, lng: 126.9780, title: '1', description: 'The capital city of South Korea.' },
          { lat: 37.5796, lng: 126.9770, title: '1.5', description: 'A historic palace in Seoul.' },
          { lat: 37.5512, lng: 126.9882, title: '2', description: 'A famous tower with panoramic views of Seoul.' },
          { lat: 37.5665, lng: 126.9780, title: '1', description: 'The capital city of South Korea.' },
          { lat: 33.4996, lng: 126.5312, title: '2.5', description: 'A beautiful island in South Korea.' },
          { lat: 37.2398, lng: 131.8653, title: '1.5', description: 'A group of small islets in the East Sea.' },

        ];

        const bounds = new window.google.maps.LatLngBounds();

        locations.forEach(location => {
          const markerElement = document.createElement('div');
          markerElement.className = 'custom-marker';
          markerElement.textContent = location.title;

          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            position: { lat: location.lat, lng: location.lng },
            map: map,
            content: markerElement,
          });

          const infoWindowContent = `
            <div class="info-window-content">
              <h3>${location.title}</h3>
              <p>${location.description}</p>
            </div>
          `;

          const infoWindow = new window.google.maps.InfoWindow({
            content: infoWindowContent,
          });

          // infoWindow.open(map, marker);

          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });

          // 각 위치를 경계에 추가
          bounds.extend(marker.position);
        });

        // 지도를 모든 마커가 보이도록 조정
        map.fitBounds(bounds);
      }
    };

    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      initMap();
    }
  }, []);

  return (
    <div>
      <h1>Google Map</h1>
      <div ref={mapRef} style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default Item1;